import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';

interface User {
  _id?: string;
  email?: string;
  fullName?: string;
  cpf?: string;
  password?: string;
  role?: string;
  status?: string;
}

interface UserFormProps {
  user: User;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const UserForm: React.FC<UserFormProps> = ({ user, handleInputChange }) => {
  return (
    <>
      <Row className="mb-0 mb-md-3">
        <Col xs={12} md={6}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label className="text-uppercase">
              Nome
              {!user._id && <sup className="ms-1 text-danger fw-bold">*</sup>}
            </Form.Label>
            <Form.Control
              type="text"
              name="fullName"
              defaultValue={user.fullName || ''}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label className="text-uppercase">
              Nível de Acesso
              {!user._id && <sup className="ms-1 text-danger fw-bold">*</sup>}
            </Form.Label>
            <Form.Control
              as="select"
              name="role"
              defaultValue={user.role || ''}
              onChange={handleInputChange}
              disabled={user._id && user.role === 'user' ? true : false}
              required
            >
              <option value="" disabled>
                Selecione o nível
              </option>
              {[
                {
                  name: 'Usuário',
                  value: 'user',
                },
                {
                  name: 'Administrador',
                  value: 'admin',
                },
              ].map((item, index) => (
                <option value={item.value} key={index}>
                  {item.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-0 mb-md-3">
        <Col xs={12} md={4}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label className="text-uppercase">
              E-mail
              {!user._id && <sup className="ms-1 text-danger fw-bold">*</sup>}
            </Form.Label>
            <Form.Control
              type="email"
              name="email"
              defaultValue={user.email || ''}
              onChange={handleInputChange}
              required
              disabled={!!user._id}
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={4}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label className="text-uppercase">
              Senha
              {!user._id && <sup className="ms-1 text-danger fw-bold">*</sup>}
            </Form.Label>
            <Form.Control
              type="password"
              name="password"
              value={user.password || ''}
              onChange={handleInputChange}
              placeholder={user._id ? 'Senha oculta por segurança.' : undefined}
              required
            />
            {user._id && (
              <sup className="text-danger fw-bold">
                Deixe como está pra manter a senha atual
              </sup>
            )}
          </Form.Group>
        </Col>
        <Col xs={12} md={4}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label className="text-uppercase">
              CPF
              {!user._id && <sup className="ms-1 text-danger fw-bold">*</sup>}
            </Form.Label>
            <Form.Control
              type="text"
              name="cpf"
              value={user.cpf || ''}
              onChange={handleInputChange}
              required
              disabled={!!user._id}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default UserForm;
