import React, { Component, ReactNode } from 'react';
import { Col, Card, Button, Dropdown } from 'react-bootstrap';
import { ButtonCallToACtion } from '../Buttons';

interface CardListProps {
  page?: number;
  pages?: number;
  countItems?: number;
  data?: any[];
  callbackPrev?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  callbackNext?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children?: ReactNode;
}

export class CardList extends Component<CardListProps> {
  render() {
    let { page = 0, pages = 0, countItems = 0, data } = this.props;
    if (data) {
      countItems = data.length || 0;
    }

    return (
      <Card className="border-0 mb-2" style={{ background: 'transparent' }}>
        <Card.Body>{this.props.children}</Card.Body>
        {page > 0 ? (
          <Card.Footer
            className="border-0 d-flex align-items-center justify-content-between"
            style={{ background: 'transparent' }}
          >
            <p className="text-muted m-0 small">
              Mostrando{' '}
              {countItems !== 1 ? `${countItems} itens` : `${countItems} item`}.
            </p>
            <div className="pagination-buttons">
              <span className="text-muted m-0 small">
                Página {page} de {pages}
              </span>
              <Button
                className={'btn-pagination ms-3 me-2'}
                style={page === 1 ? { cursor: 'not-allowed' } : {}}
                disabled={page === 1}
                onClick={this.props.callbackPrev}
              >
                Anterior
              </Button>
              <Button
                className={'btn-pagination'}
                style={page === pages ? { cursor: 'not-allowed' } : {}}
                disabled={page === pages}
                onClick={this.props.callbackNext}
              >
                Próxima
              </Button>
            </div>
          </Card.Footer>
        ) : (
          <></>
        )}
      </Card>
    );
  }
}

interface CardListHeaderProps {
  className?: string;
  children?: ReactNode;
}

export class CardListHeader extends Component<CardListHeaderProps> {
  render() {
    return (
      <Card
        className={
          'd-none d-lg-flex border-bottom border-top-0 border-end-0 border-start-0 rounded-0 mb-1 py-2 bg-color-secondary color-white ' +
          this.props.className
        }
      >
        <Card.Body className="p-3">{this.props.children}</Card.Body>
      </Card>
    );
  }
}

interface CardListHeaderItemProps {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  className?: string;
  children?: ReactNode;
}

export class CardListHeaderItem extends Component<CardListHeaderItemProps> {
  render() {
    const { xs = 12, sm = 12, md = 12, lg = 12, className = '' } = this.props;

    return (
      <Col
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
        className={
          className +
          ' color-dark-gray font-semi-bold text-break d-flex align-items-center'
        }
      >
        {this.props.children}
      </Col>
    );
  }
}

interface CardListHeaderSortItemProps {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  className?: string;
  onSort?: (sortKey: string) => void;
  sortKey?: string;
  children?: ReactNode;
}

export class CardListHeaderSortItem extends Component<CardListHeaderSortItemProps> {
  render() {
    const {
      xs = 12,
      sm = 12,
      md = 12,
      lg = 12,
      className = '',
      onSort,
      sortKey,
      children,
    } = this.props;

    const handleClick = () => {
      if (onSort && sortKey) {
        onSort(sortKey);
      }
    };

    return (
      <Col
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
        className={
          className +
          ' d-flex align-items-center card-list-header-sort color-dark-gray text-uppercase font-semi-bold text-break'
        }
      >
        {children}
        {onSort && (
          <span className="material-icons c-pointer" onClick={handleClick}>
            swap_vert
          </span>
        )}
      </Col>
    );
  }
}

interface CardListBodyProps {
  className?: string;
  children?: ReactNode;
}

export class CardListBody extends Component<CardListBodyProps> {
  render() {
    return (
      <Card
        className={`rounded-0 mb-1 card-data ${this.props.className} text-break`}
      >
        <Card.Body className="p-3">{this.props.children}</Card.Body>
      </Card>
    );
  }
}

interface CardListBodyItemProps {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  className?: string;
  value?: string;
  style?: React.CSSProperties;
  isHtml?: boolean;
  maxLength?: number;
  children?: React.ReactNode;
}

export class CardListBodyItem extends Component<CardListBodyItemProps> {
  truncateHtml = (html: string, maxLength: number): string => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    let truncatedHtml = '';
    let charCount = 0;
    let brCount = 0;

    const traverseNodes = (node: Node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        const remainingChars = maxLength - charCount;
        if (charCount + node.textContent!.length <= maxLength) {
          truncatedHtml += node.textContent;
          charCount += node.textContent!.length;
          brCount = 0;
        } else {
          truncatedHtml += node.textContent!.substring(0, remainingChars);
          charCount = maxLength;
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        const element = node as HTMLElement;
        if (element.tagName.toLowerCase() === 'br') {
          if (brCount === 0) {
            truncatedHtml += '<br />';
          }
          brCount++;
        } else if (
          element.tagName.toLowerCase() === 'p' &&
          Array.from(element.childNodes).every(
            childNode => childNode.nodeName.toLowerCase() === 'br'
          )
        ) {
          brCount++;
        } else {
          truncatedHtml += `<${element.tagName.toLowerCase()}${Array.from(
            element.attributes
          )
            .map(attr => ` ${attr.name}="${attr.value}"`)
            .join('')}>`;

          brCount = 0;

          element.childNodes.forEach(childNode => {
            if (charCount < maxLength) {
              traverseNodes(childNode);
            }
          });

          truncatedHtml += `</${element.tagName.toLowerCase()}>`;
        }
      }
    };

    doc.body.childNodes.forEach(node => {
      if (charCount < maxLength) {
        traverseNodes(node);
      }
    });

    truncatedHtml = truncatedHtml.replace(/(<br\s*\/?>\s*){2,}/g, '<br />');

    return truncatedHtml;
  };

  render() {
    const {
      xs = 12,
      sm = 12,
      md = 12,
      lg = 12,
      className = '',
      value = '',
      style = {},
      isHtml = false,
      maxLength = 100,
    } = this.props;

    const displayValue = isHtml ? this.truncateHtml(value, maxLength) : value;

    return (
      <Col
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
        className={`${className} text-break ${isHtml && 'item-html'}`}
        style={style}
      >
        {isHtml ? (
          <span dangerouslySetInnerHTML={{ __html: displayValue }} />
        ) : (
          displayValue
        )}
      </Col>
    );
  }
}

interface CardListBodyButtonProps {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  className?: string;
  title?: string;
  style?: React.CSSProperties;
  callToAction?: () => void;
}

export class CardListBodyButton extends Component<CardListBodyButtonProps> {
  render() {
    const {
      xs = 12,
      sm = 12,
      md = 12,
      lg = 12,
      className = '',
      title = '',
      style = {},
      callToAction,
    } = this.props;

    return (
      <Col
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
        className={`${className} `}
        style={style}
      >
        <ButtonCallToACtion btnText={title} onClick={callToAction} />
      </Col>
    );
  }
}

interface CardListBodyItemOptionsProps {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  className?: string;
  children?: ReactNode;
}

export class CardListBodyItemOptions extends Component<CardListBodyItemOptionsProps> {
  render() {
    const { xs = 12, sm = 12, md = 12, lg = 12, className = '' } = this.props;

    return (
      <Col
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
        className={`${className} text-break`}
      >
        <Dropdown drop="start">
          <Dropdown.Toggle
            variant="muted"
            id="dropdown-basic"
            className="p-0 text-muted"
            style={{ lineHeight: 0 }}
          >
            <span className="material-icons">settings</span>
          </Dropdown.Toggle>
          <Dropdown.Menu>{this.props.children}</Dropdown.Menu>
        </Dropdown>
      </Col>
    );
  }
}

export class CardListBodyItemWithChildren extends Component<CardListBodyItemProps> {
  render() {
    const { xs = 12, sm = 12, md = 12, lg = 12, className = '' } = this.props;
    return (
      <Col
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
        className={`${className} text-break`}
      >
        {this.props.children}
      </Col>
    );
  }
}
