import api from '../config/api';

const getAddressByZipcode = async (zipcode: string) => {
  try {
    const response = await api.get(`/cep/${zipcode}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const apiMethods = {
  getAddressByZipcode,
};

export default apiMethods;
