import React, { ReactNode } from 'react';
import { Modal, Container, Row, Col, Form, Alert } from 'react-bootstrap';
import { ButtonPrimary } from '../Buttons';
import { dateHourFormat } from '../../config/utils';
import { useAuth } from '../../context/authContext';

// Tipagem para as props do CustomModal
interface CustomModalProps {
  show: boolean;
  onHide: () => void;
  title: string;
  children: ReactNode;
  submitLabel?: string;
  onSubmit: () => void;
  errorMessage?: string;
  disabled?: boolean;
  type?: 'form' | 'view';
  size?: 'sm' | 'lg' | 'xl';
  data?: {
    createdAt?: string;
    updatedAt?: string;
  };
}

const CustomModal: React.FC<CustomModalProps> = ({
  show,
  onHide,
  title,
  children,
  submitLabel = 'Salvar',
  onSubmit,
  errorMessage = '',
  disabled = false,
  type = 'form',
  size = 'lg',
  data = {},
}) => {
  const { user } = useAuth();

  return (
    <Modal show={show} onHide={onHide} size={size}>
      <Modal.Header
        className="border-0 p-4 d-flex align-items-center bg-color-secondary color-white"
        closeButton
      >
        <Modal.Title className="d-flex align-items-center justify-content-center">
          <div className="d-flex align-items-start justify-content-center flex-column ml-3">
            <h5 className="mb-0 fw-bold">{title}</h5>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          {type === 'form' ? (
            <Form
              onSubmit={e => {
                e.preventDefault();
                onSubmit();
              }}
            >
              {children}
              {errorMessage !== '' && (
                <Row>
                  <Col xs={12} className="d-flex justify-content-center">
                    <Alert
                      variant="danger"
                      className="mb-0 text-center small w-auto"
                    >
                      {errorMessage}
                    </Alert>
                  </Col>
                </Row>
              )}
              <Row>
                <Col xs={12} className="d-flex justify-content-center">
                  <ButtonPrimary
                    type="submit"
                    btnText={submitLabel}
                    disabled={disabled}
                    className="mt-2"
                  />
                </Col>
              </Row>
            </Form>
          ) : (
            <>{children}</>
          )}
        </Container>
      </Modal.Body>
      {type !== 'form' && user?.companyRole !== 'user' && (
        <Modal.Footer>
          <Container fluid>
            <Row className="mb-2">
              <Col xs={12} md={6}>
                <small className="text-muted">
                  Criado em:{' '}
                  <strong>
                    {dateHourFormat(new Date(data?.createdAt || new Date()))}
                  </strong>
                  .
                </small>
              </Col>
              <Col xs={12} md={6} className="text-start text-md-end">
                <small className="text-muted">
                  Atualizado em:{' '}
                  <strong>
                    {dateHourFormat(new Date(data?.updatedAt || new Date()))}
                  </strong>
                  .
                </small>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      )}
    </Modal>
  );
};

interface CustomModalCompaniesProps {
  show: boolean;
  onHide: () => void;
  title?: string;
  children: ReactNode;
}

const CustomModalCompanies: React.FC<CustomModalCompaniesProps> = ({
  show,
  onHide,
  title = 'Selecionar Empresa',
  children,
}) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header
        className="border-0 p-4 d-flex align-items-center bg-color-secondary color-white"
        closeButton
      >
        <Modal.Title className="d-flex align-items-center justify-content-center">
          <div className="d-flex align-items-start justify-content-center flex-column ml-3">
            <h5 className="mb-0 fw-bold">{title}</h5>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Row>
            <Col xs={12} className="d-flex justify-content-center">
              {children}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export { CustomModal, CustomModalCompanies };
