import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import PrivateRoute from './config/privateRoute';
import { EventProvider } from './context/EventContext';

import {
  SignupPage,
  LoginPage,
  ActivatePage,
  HomePage,
  DashboardPage,
  MyProfilePage,
  MyCompanyPage,
  UsersPage,
  UsersCompaniesPage,
  EventsCompaniesPage,
  SingleEventPage,
  CreateEventsPage,
} from './pages';

const SingleEventRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/company/events/:slug"
        element={
          <PrivateRoute requireCompany companyAdminOnly>
            <EventProvider>
              <SingleEventPage />
            </EventProvider>
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

const AppRoutes: React.FC = () => {
  const returnToLoginPage = () => {
    return <Navigate to="/" />;
  };
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/cadastro" element={<SignupPage />} />
        <Route path="/ativar/:token" element={<ActivatePage />} />
        <Route
          path="/home"
          element={
            <PrivateRoute>
              <HomePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute adminOnly>
              <DashboardPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/users"
          element={
            <PrivateRoute adminOnly>
              <UsersPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/my-profile"
          element={
            <PrivateRoute>
              <MyProfilePage />
            </PrivateRoute>
          }
        />

        {/* Companhias */}
        <Route
          path="/my-company"
          element={
            <PrivateRoute requireCompany requireOwner>
              <MyCompanyPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/company/users"
          element={
            <PrivateRoute requireCompany companyAdminOnly>
              <UsersCompaniesPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/company/events"
          element={
            <PrivateRoute requireCompany companyAdminOnly>
              <EventsCompaniesPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/company/events/create"
          element={
            <PrivateRoute requireCompany companyAdminOnly>
              <CreateEventsPage />
            </PrivateRoute>
          }
        />

        {/* Eventos */}
        <Route path="/*" element={<SingleEventRoutes />} />

        <Route path="*" element={returnToLoginPage()} />
      </Routes>
    </Router>
  );
};
export default AppRoutes;
