import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faQrcode,
  faBars,
  faArrowRightFromBracket,
  faHouse,
  faArrowLeft,
} from '@fortawesome/free-solid-svg-icons';
import {
  Navbar,
  Nav,
  Accordion,
  Card,
  Container,
  Row,
  Col,
  Dropdown,
  DropdownButton,
  Button,
} from 'react-bootstrap';
import BeeThumbsUp from '../../assets/img/bee_thumbs_up.webp';
import authService from '../../services/authService';
import { useAuth } from '../../context/authContext';
import Loading from '../Loading';

function EventSidebar({
  children,
  pageName,
  pageUrl,
  loading = false,
  dateStart,
}: any) {
  const { user, selectedCompany } = useAuth();
  const [load] = useState(loading);

  const [sidebar, setSidebar] = useState(true);
  const toggleSidebar = () => setSidebar(!sidebar);

  return (
    <>
      <div className={sidebar ? 'd-flex' : `d-flex toggled`} id="wrapper">
        <div id="sidebar-wrapper" className="d-none d-lg-block">
          <div className="sidebar-heading d-flex align-items-center justify-content-center flex-column">
            <Link
              to="/home"
              className="d-flex align-content-center justify-content-center text-decoration-none"
            >
              {' '}
              <img
                src="https://fakeimg.pl/250x250/fff/d8401e/?text=beeventz"
                alt=""
                className="img-fluid mb-2 circle-img"
              />
            </Link>
          </div>
          {selectedCompany && (
            <div className="d-flex justify-content-center mb-3">
              <Button className="btn btn-qrcode">
                <FontAwesomeIcon icon={faQrcode} className="me-2" />
                LEITOR DE QR CODE
              </Button>
            </div>
          )}
          <Accordion className="mb-2 mb-md-5">
            <Card className="border-0">
              <Card.Header className="p-0 border-0">
                <NavItem icon={faHouse} title={'Home'} href={pageUrl} />
              </Card.Header>
            </Card>
          </Accordion>
          <div className="sidebar-logout-container">
            <div className="list-group list-group-flush">
              <Link
                className={`list-group-item list-group-item-action`}
                to="/company/events"
              >
                <div className="d-flex justify-content-between align-items-center">
                  <span className="d-flex align-items-center ms-3">
                    <div className="icon-container">
                      <FontAwesomeIcon
                        icon={faArrowLeft}
                        className="font-awesome"
                      />
                    </div>
                    <span className="name-list">Meus Eventos</span>
                  </span>
                </div>
              </Link>
            </div>
            <hr className="hr-sidebar" />
            <LogoutItem
              icon={'logout'}
              name={'Logout'}
              className={'c-pointer'}
              onClick={() => authService.logout()}
            />
          </div>
        </div>
        <div
          id="page-content-wrapper"
          className="d-flex justify-content-between flex-column"
        >
          <div className="d-flex flex-column w-100 h-100 overflow-auto justify-content-between">
            <div>
              <Navbar
                collapseOnSelect
                expand="lg"
                className="py-3 bg-primary justify-content-between color-white"
              >
                <div className="ms-3 d-flex justify-content-center align-items-center">
                  <FontAwesomeIcon
                    icon={faBars}
                    onClick={toggleSidebar}
                    className="c-pointer"
                    style={{ fontSize: '22px' }}
                  />
                  <div className="title-page ms-3 mb-0 lh-1">
                    <h6 className="mb-0">
                      <a
                        href={pageUrl}
                        className="text-decoration-none me-2 color-white fw-bold"
                      >
                        {pageName}
                      </a>
                    </h6>
                    <small>{dateStart}</small>
                  </div>
                </div>
                <Nav className="me-3 nav-items align-items-center">
                  {selectedCompany && (
                    <FontAwesomeIcon icon={faQrcode} className="c-pointer" />
                  )}
                  <DropdownButton
                    align={{ lg: 'end' }}
                    title={
                      <>
                        <img
                          src={BeeThumbsUp}
                          alt="avatar-user"
                          className="rounded-circle"
                        />
                      </>
                    }
                    id="dropdown-menu-align-right"
                    className="p-0 d-none d-sm-flex"
                  >
                    <Dropdown.Item
                      eventKey="4"
                      onClick={() => (window.location.href = '/my-profile')}
                    >
                      Editar Perfil
                    </Dropdown.Item>
                    {(user?.companyRole === 'owner' ||
                      user?.companyRole === 'co-owner') && (
                      <Dropdown.Item
                        eventKey="4"
                        onClick={() => (window.location.href = '/my-company')}
                      >
                        Editar Empresa
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item
                      eventKey="4"
                      onClick={() => authService.logout()}
                    >
                      Sair
                    </Dropdown.Item>
                  </DropdownButton>
                </Nav>
              </Navbar>
              <div className="p-4 bg-white">{children}</div>
            </div>
          </div>
          <div className="w-100 py-3 text-center small">
            <Container>
              <Row>
                <Col xs={12} className="d-flex justify-content-center">
                  <p className="color-dark-gray m-0">
                    © {new Date().getFullYear()} - Beeventz. Todos os Direitos
                    Reservados.
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <Loading show={load} />
    </>
  );
}

function NavItem({ href = '#', icon, title, type, collapsed, onClick }: any) {
  const location = useLocation();
  const isActive = location.pathname === href;

  return (
    <div className="list-group list-group-flush">
      <Link
        className={`list-group-item list-group-item-action ${isActive ? 'item-active' : ''}`}
        to={href}
        onClick={onClick}
      >
        <div className="d-flex justify-content-between align-items-center">
          <span className="d-flex align-items-center ms-3">
            <div className="icon-container">
              <FontAwesomeIcon icon={icon} className="font-awesome" />
            </div>
            <span className="name-list">{title}</span>
          </span>
          {type === 'collapse' ? (
            collapsed ? (
              <span className="material-icons me-1">keyboard_arrow_up</span>
            ) : (
              <span className="material-icons me-1">keyboard_arrow_down</span>
            )
          ) : (
            <span className="material-icons me-1">chevron_right</span>
          )}
        </div>
      </Link>
    </div>
  );
}

function LogoutItem(props: any) {
  const { onClick } = props;

  return (
    <div className={`list-group list-group-flush ${props.className}`}>
      <div className="list-group-item list-group-item-action" onClick={onClick}>
        <span className="d-flex align-items-center ms-3">
          <div className="icon-container">
            <FontAwesomeIcon
              icon={faArrowRightFromBracket}
              className="font-awesome"
            />
          </div>
          <span className="name-list">Sair</span>
        </span>
      </div>
    </div>
  );
}

export default EventSidebar;
