import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import {
  CardList,
  CardListHeader,
  CardListHeaderItem,
  CardListBody,
  CardListBodyItem,
  CardListBodyItemWithChildren,
} from '../../../../components/CardList';
import { FilterBar, Loading } from '../../../../components';
import Sidebar from '../../../../components/Sidebar';
import { Event } from '../../../../types/Events';
import eventService from '../../../../services/eventService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import {
  translateTypes,
  formatEventDate,
} from '../../../../config/utils/Events/utils';
import { limitText } from '../../../../config/utils';
import Swal from 'sweetalert2';

const EventsCompaniesPage: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Event[]>([]);
  const [dataInfo, setDataInfo] = useState({
    total: 0,
    page: 1,
    limit: 20,
    totalPages: 0,
  });
  const [filters, setFilters] = useState({
    page: 1,
    sort: { sortBy: 'createdAt', order: 'desc' },
    limit: 20,
    search: '',
    status: 'all',
    userType: '',
  });

  const refreshItems = async () => {
    setLoading(false);
  };

  const filterConfig = [
    {
      type: 'select' as const,
      label: 'Filtrar por status',
      name: 'status',
      options: [
        { label: 'Todos', value: 'all' },
        { label: 'Ativos', value: 'active' },
        { label: 'Inativos', value: 'inactive' },
      ],
      size: 2,
    },
    {
      type: 'select' as const,
      label: '# itens',
      name: 'limit',
      options: [
        { label: '5', value: '5' },
        { label: '10', value: '10' },
        { label: '20', value: '20' },
      ],
      size: 1,
    },
  ];

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const { page, sort, limit, search, status, userType } = filters;
      const params = {
        page,
        sortBy: sort.sortBy,
        order: sort.order,
        limit,
        search,
        status,
        userType,
      };
      const response = await eventService.fetchData(params);
      setData(response.docs);
      setDataInfo({ ...response });
    } catch (error: any) {
      Swal.fire('Erro!', error.message, 'error');
    } finally {
      refreshItems();
    }
  }, [filters]);

  useEffect(() => {
    document.title = 'Beeventz APP - Eventos';
    fetchData();
  }, [fetchData]);

  const prevPage = () => {
    setFilters(prevFilters => ({
      ...prevFilters,
      page: prevFilters.page > 1 ? prevFilters.page - 1 : prevFilters.page,
    }));
  };

  const nextPage = () => {
    setFilters(prevFilters => ({
      ...prevFilters,
      page:
        prevFilters.page < dataInfo.totalPages
          ? prevFilters.page + 1
          : prevFilters.page,
    }));
  };

  const handleInputSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFilters(prevFilters => ({ ...prevFilters, [name]: value }));
  };

  return (
    <>
      <Sidebar pageName="Eventos" pageUrl="/company/events">
        <Container fluid>
          <FilterBar
            config={filterConfig}
            filters={filters}
            onFilterChange={handleInputSearch}
            onAddClick={() => {
              navigate('/company/events/create');
            }}
          />
          <Row>
            <Col xs={12}>
              <CardList
                page={dataInfo.page}
                data={data}
                pages={dataInfo.totalPages}
                callbackNext={nextPage}
                callbackPrev={prevPage}
              >
                <CardListHeader>
                  <Row>
                    <CardListHeaderItem xs={12} lg={3}>
                      Título
                    </CardListHeaderItem>
                    <CardListHeaderItem xs={12} lg={2}>
                      Tipo
                    </CardListHeaderItem>
                    <CardListHeaderItem xs={12} lg={3}>
                      Período
                    </CardListHeaderItem>
                    <CardListHeaderItem xs={12} lg={3}>
                      Local
                    </CardListHeaderItem>
                    <CardListHeaderItem
                      xs={12}
                      lg={1}
                      className="justify-content-center"
                    />
                  </Row>
                </CardListHeader>
                {data.map((data, index) => (
                  <CardListBody key={index}>
                    <Row>
                      <CardListBodyItem
                        xs={12}
                        lg={3}
                        className="d-inline-flex align-items-center text-muted small"
                        value={limitText(data.title, 50)}
                      />
                      <CardListBodyItem
                        xs={12}
                        lg={2}
                        className="d-inline-flex align-items-center text-muted small"
                        value={translateTypes(data.type)}
                      />
                      <CardListBodyItem
                        xs={12}
                        lg={3}
                        className="d-inline-flex align-items-center text-muted small"
                        value={`${formatEventDate(data.start)} até ${formatEventDate(data.end)}`}
                      />
                      <CardListBodyItemWithChildren
                        xs={12}
                        lg={3}
                        className="d-inline-flex align-items-center text-muted small"
                      >
                        <a
                          href={`https://maps.google.com/?q=${data.venueAddress}`}
                          className="text-decoration-none"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <strong>{limitText(data.venueName, 50)}</strong>
                        </a>
                      </CardListBodyItemWithChildren>
                      <CardListBodyItemWithChildren
                        xs={12}
                        lg={1}
                        className="d-inline-flex align-items-center justify-content-center"
                      >
                        <Link
                          to={`/company/events/${data.slug}`}
                          className="text-decoration-none"
                        >
                          <FontAwesomeIcon icon={faEye} />
                        </Link>
                      </CardListBodyItemWithChildren>
                    </Row>
                  </CardListBody>
                ))}
                {data.length === 0 ? (
                  <>
                    <CardListBody>
                      <Col
                        xs={12}
                        className="d-inline-flex align-items-center text-muted small justify-content-center"
                      >
                        Nenhum item encontrado.
                      </Col>
                    </CardListBody>
                  </>
                ) : (
                  <></>
                )}
              </CardList>
            </Col>
          </Row>
        </Container>
      </Sidebar>
      <Loading show={loading} />
    </>
  );
};

export default EventsCompaniesPage;
