import { useEffect, useRef } from 'react';
import { Row, Col, Form, Image, Card } from 'react-bootstrap';

import { CreateEventPayload } from '../../../../../../types/Events';
import { getAddressComponent } from '../../../../../../config/utils';

// Images
import Musica from '../../../../../../assets/img/music.png';
import Teatro from '../../../../../../assets/img/theatre.png';
import Danca from '../../../../../../assets/img/dance.png';
import Festa from '../../../../../../assets/img/party.png';

interface CreateEventProps {
  item: CreateEventPayload;
  setItem: React.Dispatch<React.SetStateAction<CreateEventPayload>>;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error: boolean;
  typeOfError: string;
  slugLoading: boolean;
  slug: string;
  isSlugAvailable: boolean;
}

export default function CreateEvent({
  item,
  setItem,
  handleInputChange,
  error,
  typeOfError,
  slugLoading,
  slug,
  isSlugAvailable,
}: CreateEventProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const initAutocomplete = () => {
      if (window.google) {
        const autocomplete = new window.google.maps.places.Autocomplete(
          inputRef.current!,
          {
            types: ['geocode', 'establishment'],
            componentRestrictions: { country: 'br' },
          }
        );

        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();
          if (place.geometry) {
            const addressComponents = place.address_components;
            const city = getAddressComponent(
              addressComponents,
              'administrative_area_level_2'
            );
            const state = getAddressComponent(
              addressComponents,
              'administrative_area_level_1'
            );
            const country = getAddressComponent(addressComponents, 'country');
            const zipCode = getAddressComponent(
              addressComponents,
              'postal_code'
            );

            setItem((prevItem: CreateEventPayload) => ({
              ...prevItem,
              venueAddress: place.formatted_address,
              city,
              state,
              country,
              zipCode,
            }));
          }
        });
      }
    };

    if (!window.google) {
      const checkGoogleInterval = setInterval(() => {
        if (window.google) {
          clearInterval(checkGoogleInterval);
          initAutocomplete();
        }
      }, 100);
    } else {
      initAutocomplete();
    }
  }, [setItem]);

  return (
    <Row>
      <Col xs={12} className="mb-3">
        <h3 className="color-primary">Informações gerais</h3>
      </Col>
      <Col xs={12}>
        <Row className="mb-3">
          <Col xs={'auto'}>
            <Card
              className={`card-type-event ${item.type === 'music' && 'card-active'}`}
              onClick={() => setItem({ ...item, type: 'music' })}
            >
              <Image src={Musica} />
              <h6>Música</h6>
              <Form.Check
                type="radio"
                name="type"
                onChange={() => setItem({ ...item, type: 'music' })}
                checked={item.type === 'music'}
              />
            </Card>
          </Col>
          <Col xs={'auto'}>
            <Card
              className={`card-type-event ${item.type === 'theater' && 'card-active'}`}
              onClick={() => setItem({ ...item, type: 'theater' })}
            >
              <Image src={Teatro} />
              <h6>Teatro</h6>
              <Form.Check
                type="radio"
                name="type"
                onChange={() => setItem({ ...item, type: 'theater' })}
                checked={item.type === 'theater'}
              />
            </Card>
          </Col>
          <Col xs={'auto'}>
            <Card
              className={`card-type-event ${item.type === 'dance' && 'card-active'}`}
              onClick={() => setItem({ ...item, type: 'dance' })}
            >
              <Image src={Danca} />
              <h6>Dança</h6>
              <Form.Check
                type="radio"
                name="type"
                onChange={() => setItem({ ...item, type: 'dance' })}
                checked={item.type === 'dance'}
              />
            </Card>
          </Col>
          <Col xs={'auto'}>
            <Card
              className={`card-type-event ${item.type === 'party' && 'card-active'}`}
              onClick={() => setItem({ ...item, type: 'party' })}
            >
              <Image src={Festa} />
              <h6>Festas</h6>
              <Form.Check
                type="radio"
                name="item"
                onChange={() => setItem({ ...item, type: 'party' })}
                checked={item.type === 'party'}
              />
            </Card>
          </Col>
        </Row>
      </Col>
      <Col xs={12}>
        <Form.Group className="mb-3">
          <Form.Label>
            Título do Evento <strong className="text-danger">*</strong>
          </Form.Label>
          <Form.Control
            type="text"
            name="title"
            value={item.title}
            onChange={handleInputChange}
            required
            className="mb-2"
          />
          <div className="slug-confirmation">
            {slugLoading ? (
              <div
                className="spinner-border spinner-border-sm text-secondary me-1"
                role="status"
              />
            ) : (
              <span
                className="material-icons me-1"
                style={{
                  color: isSlugAvailable
                    ? 'green'
                    : slug !== ''
                      ? 'red'
                      : '#595858',
                }}
              >
                {isSlugAvailable
                  ? 'check_circle'
                  : slug !== ''
                    ? 'cancel'
                    : 'pending'}
              </span>
            )}
            <span>
              A URL do evento será: https://beevents.com.br/evento/
              <span className="text-decoration-underline color-dark fw-bold">{`${slug}`}</span>
            </span>
          </div>
        </Form.Group>
      </Col>
      <Col xs={6} className="mb-2">
        <Form.Group className="mb-3">
          <Form.Label>
            Local <strong className="text-danger">*</strong>
          </Form.Label>
          <Form.Control
            type="text"
            name="venueName"
            value={item.venueName}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
      </Col>
      <Col xs={6} className="mb-2">
        <Form.Group className="mb-3">
          <Form.Label>
            Endereço do Local <strong className="text-danger">*</strong>
          </Form.Label>
          <Form.Control
            type="text"
            ref={inputRef}
            name="venueAddress"
            placeholder=""
            value={item.venueAddress}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
      </Col>
      <Col xs={12} className="mb-2">
        <Form.Group className="mb-3">
          <Form.Label>
            Descrição <strong className="text-danger">*</strong>
          </Form.Label>
          <Form.Control
            as="textarea"
            name="description"
            value={item.description}
            onChange={handleInputChange}
            rows={5}
            required
          />
        </Form.Group>
      </Col>
      <Col xs={12} md={4} className="mb-2">
        <Form.Group className="mb-3">
          <Form.Label>
            Início <strong className="text-danger">*</strong>
          </Form.Label>
          <Form.Control
            type="datetime-local"
            name="start"
            value={item.start}
            onChange={handleInputChange}
            required
          />
          {error && typeOfError === 'start' && (
            <sup className="text-danger fw-bold">
              Horário de início deve ser maior que a data e hora atual.
            </sup>
          )}
        </Form.Group>
      </Col>
      <Col xs={12} md={4}>
        <Form.Group className="mb-3">
          <Form.Label>
            Fim <strong className="text-danger">*</strong>
          </Form.Label>
          <Form.Control
            type="datetime-local"
            name="end"
            value={item.end}
            onChange={handleInputChange}
            required
          />
          {error && typeOfError === 'end' && (
            <sup className="text-danger fw-bold">
              Horário de fim deve ser maior que o horário de inicio.
            </sup>
          )}
        </Form.Group>
      </Col>
      <Col xs={12} md={4}>
        <Form.Group className="mb-3">
          <Form.Label>
            Capacidade <strong className="text-danger">*</strong>
          </Form.Label>
          <Form.Control
            type="number"
            name="capacity"
            value={item.capacity}
            min={1}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
      </Col>
    </Row>
  );
}
