import React from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useAuth } from '../../context/authContext';

interface FilterBarProps {
  config: Array<{
    name: string;
    label?: string;
    type: 'text' | 'select' | 'date';
    size: number;
    options?: Array<{ value: string; label: string }>;
  }>;
  filters: { [key: string]: any };
  onFilterChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onAddClick?: () => void;
  onAddText?: string;
  adminOnly?: boolean;
}

const FilterBar: React.FC<FilterBarProps> = ({
  config,
  filters,
  onFilterChange,
  onAddClick,
  onAddText,
}) => {
  const { user } = useAuth();

  return (
    <>
      {onAddClick &&
        (user?.role === 'admin' || user?.companyRole !== 'user') && (
          <Row className="m-2 justify-content-end">
            <Col xs={12} md={4} className="d-flex justify-content-end mb-2">
              <Button
                className="custom-btn btn-app btt-add d-flex align-items-center justify-content-center"
                onClick={onAddClick}
              >
                <span
                  className="material-icons p-0 me-2"
                  style={{ fontSize: 20 }}
                >
                  add_circle_outline
                </span>
                {onAddText || 'Adicionar'}
              </Button>
            </Col>
          </Row>
        )}
      <Row className="m-2 justify-content-end">
        {config.map((item, index) => (
          <Col key={index} xs={12} sm={item.size} className="text-right">
            {item.label && <small>{item.label}</small>}
            {item.type === 'text' && (
              <Form.Control
                type="text"
                className="form-control input-search"
                name={item.name}
                value={filters[item.name] || ''}
                onChange={onFilterChange}
              />
            )}
            {item.type === 'select' && (
              <Form.Control
                as="select"
                className="form-control input-search"
                name={item.name}
                value={filters[item.name] || ''}
                onChange={onFilterChange}
              >
                {item.options?.map((option, idx) => (
                  <option key={idx} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
            )}
            {item.type === 'date' && (
              <input
                type="date"
                className="form-control input-search"
                name={item.name}
                value={filters[item.name] || ''}
                onChange={onFilterChange}
              />
            )}
          </Col>
        ))}
      </Row>
    </>
  );
};

export default FilterBar;
