import api from '../config/api';
import { CreateEventPayload } from '../types/Events';

const fetchData = async ({
  page = 1,
  sortBy = 'createdAt',
  order = 'desc',
  limit = 10,
  search = '',
  status = 'all',
}) => {
  const response = await api.get(
    `/events?paginate=true&page=${page}&sortBy=${sortBy}&order=${order}&limit=${limit}&search=${search}&status=${status}`
  );
  return response.data;
};

const getEvent = async (id: string) => {
  const response = await api.get(`/events/${id}`);
  return response.data;
};

const getEventBySlug = async (slug: string) => {
  const response = await api.get(`/events/slug/${slug}`);
  return response.data;
};

const isSlugAvailable = async (data: any) => {
  const response = await api.post(`/events/check-slug`, data);
  return response.data.slug;
};

const createEvent = async (data: CreateEventPayload) => {
  const response = await api.post('/events', data);
  return response.data;
};

const apiMethods = {
  fetchData,
  getEvent,
  getEventBySlug,
  isSlugAvailable,
  createEvent,
};

export default apiMethods;
