export function translateTypes(eventType: string) {
  switch (eventType) {
    case 'music':
      return 'Música';
    case 'theatre':
      return 'Teatro';
    case 'dance':
      return 'Dança';
    case 'party':
      return 'Festa';
    case 'event':
      return 'Evento';
    case 'conference':
      return 'Conferência';
    case 'workshop':
      return 'Workshop';
    default:
      return eventType;
  }
}

export function formatEventDate(dateString: string | undefined) {
  if (!dateString) {
    return '';
  }
  const date = new Date(dateString);
  const formattedDate = new Intl.DateTimeFormat(navigator.language, {
    day: '2-digit',
    month: 'short',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  }).format(date);

  return formattedDate;
}
