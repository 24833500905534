import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  PropsWithChildren,
} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import eventService from '../services/eventService';
import { Event } from '../types/Events';

interface EventContextType {
  event: Event | null;
  setEvent: React.Dispatch<React.SetStateAction<Event | null>>;
}

const EventContext = createContext<EventContextType | undefined>(undefined);

export const useEvent = () => {
  const context = useContext(EventContext);
  if (!context) {
    throw new Error('useEvent must be used within an EventProvider');
  }
  return context;
};

export const EventProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const { slug } = useParams<{ slug: string }>();
  const [event, setEvent] = useState<Event | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        if (slug) {
          const response = await eventService.getEventBySlug(slug);
          if (!response || response.status === 404) {
            navigate('/company/events');
          } else {
            setEvent(response);
          }
        } else {
          navigate('/company/events');
        }
      } catch (error) {
        console.error('Erro ao buscar o evento', error);
        navigate('/company/events');
      }
    };

    fetchEvent();
  }, [slug, navigate]);

  return (
    <EventContext.Provider value={{ event, setEvent }}>
      {children}
    </EventContext.Provider>
  );
};
