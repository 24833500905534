import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';

interface Company {
  legalName?: string;
  fullName?: string;
  cnpj?: string;
  phone?: string;
  addressStreet?: string;
  addressNumber?: string;
  addressNeighborhood?: string;
  addressComplement?: string;
  addressCity?: string;
  addressState?: string;
  addressZipCode?: string;
}

interface CompanyFormProps {
  company: Company;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CompanyForm: React.FC<CompanyFormProps> = ({
  company,
  handleInputChange,
}) => {
  return (
    <>
      <Row className="mb-0 mb-md-3">
        <Col xs={12} md={6}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label className="text-uppercase">
              CNPJ
              <sup className="ms-1 text-danger fw-bold">*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              name="cnpj"
              defaultValue={company.cnpj || ''}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label className="text-uppercase">
              Telefone
              <sup className="ms-1 text-danger fw-bold">*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              name="phone"
              defaultValue={company.phone || ''}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-0 mb-md-3">
        <Col xs={12} md={6}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label className="text-uppercase">
              Nome Fantasia
              <sup className="ms-1 text-danger fw-bold">*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              name="fullName"
              defaultValue={company.fullName || ''}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label className="text-uppercase">
              Razão Social
              <sup className="ms-1 text-danger fw-bold">*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              name="legalName"
              defaultValue={company.legalName || ''}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-0 mb-md-3">
        <Col xs={12} md={4}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label className="text-uppercase">CEP</Form.Label>
            <Form.Control
              type="number"
              name="addressZipCode"
              defaultValue={company.addressZipCode || ''}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={4}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label className="text-uppercase">Rua</Form.Label>
            <Form.Control
              type="text"
              name="addressStreet"
              defaultValue={company.addressStreet || ''}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={4}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label className="text-uppercase">Número</Form.Label>
            <Form.Control
              type="number"
              name="addressNumber"
              defaultValue={company.addressNumber || ''}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-0 mb-md-3">
        <Col xs={12} md={4}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label className="text-uppercase">Bairro</Form.Label>
            <Form.Control
              type="text"
              name="addressNeighborhood"
              defaultValue={company.addressNeighborhood || ''}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={4}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label className="text-uppercase">Cidade</Form.Label>
            <Form.Control
              type="text"
              name="addressCity"
              defaultValue={company.addressCity || ''}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={4}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label className="text-uppercase">UF</Form.Label>
            <Form.Control
              as="select"
              name="addressState"
              onChange={handleInputChange}
              value={company.addressState || ''}
            >
              <option value="" disabled></option>
              {[
                'AC',
                'AL',
                'AP',
                'AM',
                'BA',
                'CE',
                'DF',
                'ES',
                'GO',
                'MA',
                'MT',
                'MS',
                'MG',
                'PA',
                'PB',
                'PR',
                'PE',
                'PI',
                'RJ',
                'RN',
                'RS',
                'RO',
                'RR',
                'SC',
                'SP',
                'SE',
                'TO',
              ].map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default CompanyForm;
