import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { Company } from '../../../../../types/Companies';

interface CompanyFormProps {
  company: Company | null | undefined;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const MyCompanyForm: React.FC<CompanyFormProps> = ({
  company,
  handleInputChange,
}) => {
  return (
    <>
      <Row className="mb-0 mb-md-3">
        <Col xs={4}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label>CNPJ</Form.Label>
            <Form.Control
              type="text"
              name="cnpj"
              defaultValue={company?.cnpj || ''}
              onChange={handleInputChange}
              disabled
            />
            <sup className="text-danger fw-bold">Não é possível alterar.</sup>
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label>Razão Social</Form.Label>
            <Form.Control
              type="text"
              name="legalName"
              defaultValue={company?.legalName || ''}
              onChange={handleInputChange}
              disabled
            />
            <sup className="text-danger fw-bold">Não é possível alterar.</sup>
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label>Nome Fantasia</Form.Label>
            <Form.Control
              type="text"
              name="fullName"
              defaultValue={company?.fullName || ''}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <h4>Endereço</h4>
      <Row className="mb-0 mb-md-3">
        <Col xs={12} md={4}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label>CEP</Form.Label>
            <Form.Control
              type="number"
              name="addressZipCode"
              defaultValue={company?.addressZipCode || ''}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={4}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label>Rua</Form.Label>
            <Form.Control
              type="text"
              name="addressStreet"
              defaultValue={company?.addressStreet || ''}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={4}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label>Número</Form.Label>
            <Form.Control
              type="number"
              name="addressNumber"
              defaultValue={company?.addressNumber || ''}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-0 mb-md-3">
        <Col xs={12} md={4}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label>Bairro</Form.Label>
            <Form.Control
              type="text"
              name="addressNeighborhood"
              defaultValue={company?.addressNeighborhood || ''}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={4}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label>Cidade</Form.Label>
            <Form.Control
              type="text"
              name="addressCity"
              defaultValue={company?.addressCity || ''}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={4}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label>UF</Form.Label>
            <Form.Control
              as="select"
              name="addressState"
              value={company?.addressState || ''}
              onChange={handleInputChange}
            >
              <option value="" disabled></option>
              {[
                'AC',
                'AL',
                'AP',
                'AM',
                'BA',
                'CE',
                'DF',
                'ES',
                'GO',
                'MA',
                'MT',
                'MS',
                'MG',
                'PA',
                'PB',
                'PR',
                'PE',
                'PI',
                'RJ',
                'RN',
                'RS',
                'RO',
                'RR',
                'SC',
                'SP',
                'SE',
                'TO',
              ].map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <h4>Contato</h4>
      <Row className="mb-0 mb-md-3">
        <Col xs={12} md={4}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label>Telefone</Form.Label>
            <Form.Control
              type="text"
              name="phone"
              defaultValue={company?.phone || ''}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={4}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label>E-mail</Form.Label>
            <Form.Control
              type="email"
              name="email"
              defaultValue={company?.email || ''}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <h4>Redes Sociais</h4>
      <Row className="mb-0 mb-md-3">
        <Col xs={12} md={3}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label>URL do Facebook</Form.Label>
            <Form.Control
              type="text"
              name="socialMediaFacebookURL"
              defaultValue={company?.socialMediaFacebookURL || ''}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={3}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label>URL do Instagram</Form.Label>
            <Form.Control
              type="text"
              name="socialMediaInstagramURL"
              defaultValue={company?.socialMediaInstagramURL || ''}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={3}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label>URL do Twitter/X</Form.Label>
            <Form.Control
              type="text"
              name="socialMediaTwitterURL"
              defaultValue={company?.socialMediaTwitterURL || ''}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={3}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label>URL do TikTok</Form.Label>
            <Form.Control
              type="text"
              name="socialMediaTikTokURL"
              defaultValue={company?.socialMediaTikTokURL || ''}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-0 mb-md-3">
        <Col xs={12}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label>Descrição da Empresa</Form.Label>
            <Form.Control
              as="textarea"
              name="companyDescription"
              defaultValue={company?.companyDescription || ''}
              onChange={handleInputChange}
              rows={4}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default MyCompanyForm;
