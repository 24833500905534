import api from '../config/api';

const findData = async () => {
  const response = await api.get(`/companies?status=active`);
  return response.data;
};

const findDataByID = async (id: string) => {
  const response = await api.get(`/companies/${id}`);
  return response.data;
};

const selectCompany = async (id: string | null) => {
  try {
    const response = await api.post(`/companies/${id}/select`);
    return response.data;
  } catch (error: any) {
    console.log(error.response.data);
    throw error.response.data;
  }
};

const createCompany = async (data: any) => {
  try {
    const response = await api.post('/companies', data);
    return response.data;
  } catch (error: any) {
    console.log(error.response.data);
    throw error.response.data;
  }
};

const updateCompany = async (data: any) => {
  try {
    const response = await api.patch(`/companies/${data._id}`, data);
    return response.data;
  } catch (error: any) {
    console.log(error.response.data);
    throw error.response.data;
  }
};

const apiMethods = {
  findData,
  findDataByID,
  selectCompany,
  createCompany,
  updateCompany,
};
export default apiMethods;
