import React, { useEffect, useState, useCallback } from 'react';
import Sidebar from '../../../../components/Sidebar';
import { Container, Row, Col } from 'react-bootstrap';
import { useAuth } from '../../../../context/authContext';
import { getFirstName } from '../../../../config/utils';
import configService from '../../../../services/configService';
import companyService from '../../../../services/companyService';
import { Company } from '../../../../types/Companies';
import Loading from '../../../../components/Loading';
import { MyCompanyForm } from '../../../../components/Forms';
import { ButtonPrimary } from '../../../../components/Buttons';
import Swal from 'sweetalert2';

const MyCompanyPage: React.FC = () => {
  const { user, selectedCompany } = useAuth();

  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState<Company | null>();
  const [item, setItem] = useState({
    _id: selectedCompany?._id ?? '',
  });

  const refreshItems = async () => {
    setLoading(false);
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      if (selectedCompany?._id) {
        const response = await companyService.findDataByID(selectedCompany._id);
        setCompany(response);
      } else {
        setCompany(null);
      }
    } catch (error: any) {
      Swal.fire('Erro!', error.message, 'error');
    } finally {
      refreshItems();
    }
  }, [selectedCompany?._id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === 'addressZipCode') {
      const cleanedValue = value.replace(/[^0-9]/g, '').slice(0, 8);
      if (cleanedValue.length === 8) {
        setLoading(true);
        try {
          const response =
            await configService.getAddressByZipcode(cleanedValue);
          setItem(prevItem => ({
            ...prevItem,
            addressStreet: response.logradouro,
            addressNeighborhood: response.bairro,
            addressCity: response.localidade,
            addressState: response.uf,
            addressZipCode: cleanedValue,
          }));
        } catch (error: any) {
          Swal.fire('Erro!', error.message, 'error');
        }
        setLoading(false);
      } else {
        setItem(prevItem => ({
          ...prevItem,
          addressStreet: '',
          addressNeighborhood: '',
          addressCity: '',
          addressState: '',
        }));
      }
    } else {
      setItem(prevItem => ({
        ...prevItem,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    try {
      await companyService.updateCompany(item);
      Swal.fire({
        icon: 'success',
        title: 'Dados atualizados com sucesso!',
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error: any) {
      Swal.fire('Erro!', error.message, 'error');
    } finally {
      fetchData();
      setLoading(false);
    }
  };

  return (
    <>
      <Sidebar pageName="Minha empresa" pageUrl="/my-company">
        <Container fluid>
          <Row className="mb-4">
            <Col xs={12}>
              <h2>
                Essa é a página da sua empresa,{' '}
                {getFirstName(user?.fullName ?? '')}!
              </h2>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <form onSubmit={handleSubmit}>
                <MyCompanyForm
                  company={company}
                  handleInputChange={handleInputChange}
                />
                <Row>
                  <Col xs={12} className="d-flex justify-content-end">
                    <ButtonPrimary
                      type="submit"
                      btnText={'Salvar'}
                      className="mt-2"
                    />
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Container>
      </Sidebar>
      <Loading show={loading} />
    </>
  );
};

export default MyCompanyPage;
