import api from '../config/api';

const fetchData = async ({
  page = 1,
  sortBy = 'createdAt',
  order = 'desc',
  limit = 10,
  search = '',
  status = 'all',
  userType = 'all',
}) => {
  const response = await api.get(
    `/users?paginate=true&page=${page}&sortBy=${sortBy}&order=${order}&limit=${limit}&search=${search}&status=${status}&userType=${userType}`
  );
  return response.data;
};

const signUp = async (user: any) => {
  const response = await api.post('/auth/signup', user);
  return response.data;
};

const getMyData = async () => {
  const response = await api.get('/users/my-info');
  return response.data;
};

const updateMyData = async (data: any) => {
  const response = await api.patch('/users/my-info', data);
  return response.data;
};

const updateMyPassword = async (data: any) => {
  const response = await api.patch('/users/my-password', data);
  return response.data;
};

const apiMethods = {
  signUp,
  fetchData,
  getMyData,
  updateMyData,
  updateMyPassword,
};

export default apiMethods;
