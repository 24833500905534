import { useState } from 'react';
import { EventSidebar } from '../../../../../components';
import { useEvent } from '../../../../../context/EventContext';
import { formatEventDate } from '../../../../../config/utils/Events/utils';

const SingleEventPage: React.FC = () => {
  const { event } = useEvent();
  const [load] = useState(false);
  return (
    <EventSidebar
      pageName={event?.title}
      pageUrl={`/company/events/${event?.slug}`}
      dateStart={formatEventDate(event?.start)}
      loading={load}
    >
      <h1>Single Event Page</h1>
      <p>Welcome to the single event page!</p>
    </EventSidebar>
  );
};

export default SingleEventPage;
