import React, { useState } from 'react';
import { Row, Col, Form, InputGroup } from 'react-bootstrap';
import { UserPassword } from '../../../../types/User';

interface MyUserFormProps {
  user: UserPassword;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const MyPassowrdForm: React.FC<MyUserFormProps> = ({
  user,
  handleInputChange,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  return (
    <>
      <Row className="mb-0 mb-md-3">
        <Col xs={6}>
          <Form.Group>
            <Form.Label>
              Senha
              <sup className="ms-1 text-danger fw-bold">*</sup>
            </Form.Label>
            <InputGroup>
              <Form.Control
                type={showPassword ? 'text' : 'password'}
                name="password"
                defaultValue={user.password}
                onChange={handleInputChange}
                required
              />
              <InputGroup.Text
                onClick={() => setShowPassword(!showPassword)}
                className="c-pointer"
              >
                <span
                  className={`material-icons me-1 visibility-icon ${showPassword ? 'visible' : 'hidden'}`}
                >
                  {showPassword ? 'visibility_off' : 'visibility'}
                </span>
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Col>
        <Col xs={6}>
          <Form.Group>
            <Form.Label>
              Confirmar Nova Senha
              <sup className="ms-1 text-danger fw-bold">*</sup>
            </Form.Label>
            <InputGroup>
              <Form.Control
                type={showConfirmPassword ? 'text' : 'password'}
                name="confirmPassword"
                defaultValue={user.confirmPassword}
                onChange={handleInputChange}
                required
              />
              <InputGroup.Text
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                className="c-pointer"
              >
                <span
                  className={`material-icons me-1 visibility-icon ${showConfirmPassword ? 'visible' : 'hidden'}`}
                >
                  {showConfirmPassword ? 'visibility_off' : 'visibility'}
                </span>
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default MyPassowrdForm;
