import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { User } from '../../../../types/User';

interface MyUserFormProps {
  user: User | null | undefined;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const MyUserForm: React.FC<MyUserFormProps> = ({ user, handleInputChange }) => {
  return (
    <>
      <Row className="mb-0 mb-md-3">
        <Col xs={6}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label>E-mail</Form.Label>
            <Form.Control
              type="email"
              name="email"
              defaultValue={user?.email || ''}
              onChange={handleInputChange}
              disabled
            />
            <sup className="text-danger fw-bold">Não é possível alterar.</sup>
          </Form.Group>
        </Col>
        <Col xs={6}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label>CPF</Form.Label>
            <Form.Control
              type="text"
              name="cpf"
              defaultValue={user?.cpf || ''}
              onChange={handleInputChange}
              disabled
            />
            <sup className="text-danger fw-bold">Não é possível alterar.</sup>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-0 mb-md-3">
        <Col xs={6}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label>Nome Completo</Form.Label>
            <Form.Control
              type="text"
              name="fullName"
              defaultValue={user?.fullName || ''}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
        <Col xs={6}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label>Telefone</Form.Label>
            <Form.Control
              type="text"
              name="phone"
              defaultValue={user?.phone || ''}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default MyUserForm;
