import React, { useEffect, useState, useCallback } from 'react';
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
import {
  CardList,
  CardListHeader,
  CardListHeaderItem,
  CardListBody,
  CardListBodyItemOptions,
  CardListBodyItem,
} from '../../../components/CardList';
import Sidebar from '../../../components/Sidebar';
import FilterBar from '../../../components/FilterBar';
import userService from '../../../services/userService';
import { CustomModal } from '../../../components/Modal';
import { UserForm } from '../../../components/Forms';
import Swal from 'sweetalert2';

interface User {
  _id?: string;
  fullName: string;
  email: string;
  role: string;
  status: string;
  companyRole: string;
  createdAt: string;
  updatedAt: string;
}

const emptyUser: User = {
  _id: '',
  fullName: '',
  email: '',
  role: '',
  status: '',
  companyRole: '',
  createdAt: '',
  updatedAt: '',
};

const UsersPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<User[]>([]);
  const [user, setUser] = useState<User>(emptyUser);
  const [errorMessage] = useState('');
  const [dataInfo, setDataInfo] = useState({
    total: 0,
    page: 1,
    limit: 20,
    totalPages: 0,
  });
  const [filters, setFilters] = useState({
    page: 1,
    sort: { sortBy: 'createdAt', order: 'desc' },
    limit: 20,
    search: '',
    status: 'all',
    userType: '',
  });
  const filterConfig = [
    {
      type: 'text' as const,
      label: 'Pesquisar pelo nome',
      name: 'search',
      size: 3,
    },
    {
      type: 'select' as const,
      label: 'Filtrar por status',
      name: 'status',
      options: [
        { label: 'Todos', value: 'all' },
        { label: 'Ativos', value: 'active' },
        { label: 'Inativos', value: 'inactive' },
      ],
      size: 2,
    },
    {
      type: 'select' as const,
      label: 'Tipo de usuário',
      name: 'userType',
      options: [
        { label: 'Todos', value: '' },
        { label: 'Administrador', value: 'admin' },
        { label: 'Usuário', value: 'user' },
      ],
      size: 2,
    },
    {
      type: 'select' as const,
      label: '# itens',
      name: 'limit',
      options: [
        { label: '5', value: '5' },
        { label: '10', value: '10' },
        { label: '20', value: '20' },
      ],
      size: 1,
    },
  ];

  const [showModalEditAdd, setShowModalEditAdd] = useState(false);

  const refreshItems = async () => {
    setLoading(false);
  };

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    try {
      const { page, sort, limit, search, status, userType } = filters;
      const params = {
        page,
        sortBy: sort.sortBy,
        order: sort.order,
        limit,
        search,
        status,
        userType,
      };
      const response = await userService.fetchData(params);
      setData(response.docs);
      setDataInfo({ ...response });
    } catch (error: any) {
      Swal.fire('Erro!', error.message, 'error');
    } finally {
      refreshItems();
    }
  }, [filters]);

  useEffect(() => {
    document.title = 'Beeventz APP - Usuários';
    fetchUsers();
  }, [fetchUsers]);

  const prevPage = () => {
    setFilters(prevFilters => ({
      ...prevFilters,
      page: prevFilters.page > 1 ? prevFilters.page - 1 : prevFilters.page,
    }));
  };

  const nextPage = () => {
    setFilters(prevFilters => ({
      ...prevFilters,
      page:
        prevFilters.page < dataInfo.totalPages
          ? prevFilters.page + 1
          : prevFilters.page,
    }));
  };

  const handleInputSearch = (event: any) => {
    const { name, value } = event.target;
    setFilters(prevFilters => ({ ...prevFilters, [name]: value }));
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setUser(prevItem => ({
      ...prevItem,
      [name]: value,
    }));
  };

  const submitData = async () => {
    setLoading(true);
    try {
      console.log(user);
    } catch (error: any) {
      Swal.fire('Erro!', error.message, 'error');
    } finally {
      refreshItems();
    }
  };

  useEffect(() => {
    console.log(user);
  }, [user]);

  return (
    <Sidebar pageName="Usuários" pageUrl="/users" loading={loading}>
      <Container fluid>
        <FilterBar
          config={filterConfig}
          filters={filters}
          onFilterChange={handleInputSearch}
          onAddClick={() => {
            setUser(emptyUser);
            setShowModalEditAdd(true);
          }}
        />
        <Row>
          <Col xs={12}>
            <CardList
              page={dataInfo.page}
              data={data}
              pages={dataInfo.totalPages}
              callbackNext={nextPage}
              callbackPrev={prevPage}
            >
              <CardListHeader>
                <Row>
                  <CardListHeaderItem xs={12} lg={4}>
                    Nome
                  </CardListHeaderItem>
                  <CardListHeaderItem xs={12} lg={3}>
                    E-mail
                  </CardListHeaderItem>
                  <CardListHeaderItem
                    xs={12}
                    lg={2}
                    className="justify-content-center"
                  >
                    Status
                  </CardListHeaderItem>
                  <CardListHeaderItem
                    xs={12}
                    lg={2}
                    className="justify-content-center"
                  >
                    Administrativo?
                  </CardListHeaderItem>
                  <CardListHeaderItem
                    xs={12}
                    lg={1}
                    className="justify-content-center"
                  />
                </Row>
              </CardListHeader>
              {data.map((data, index) => (
                <CardListBody key={index}>
                  <Row>
                    <CardListBodyItem
                      xs={12}
                      lg={4}
                      className="d-inline-flex align-items-center text-muted small"
                      value={data.fullName}
                    />
                    <CardListBodyItem
                      xs={12}
                      lg={3}
                      className="d-inline-flex align-items-center text-muted small"
                      value={data.email}
                    />
                    <CardListBodyItem
                      xs={12}
                      lg={2}
                      className={`d-inline-flex align-items-center justify-content-center small ${
                        (data.status === 'active' && 'fw-bold color-success') ||
                        (data.status === 'inactive' && 'fw-bold color-danger')
                      }`}
                      value={data.status === 'active' ? 'Ativo' : 'Inativo'}
                    />
                    <CardListBodyItem
                      xs={12}
                      lg={2}
                      className={`d-inline-flex align-items-center justify-content-center fw-bold ${
                        data.role === 'admin' ? 'color-success' : 'color-danger'
                      }`}
                      value={data.role === 'admin' ? 'Sim' : 'Não'}
                    />
                    <CardListBodyItemOptions
                      xs={12}
                      lg={1}
                      className="d-inline-flex align-items-center justify-content-center"
                    >
                      <Dropdown.Item
                        className="text-primary font-semi-bold text-center"
                        onClick={() => {
                          setUser(data);
                          setShowModalEditAdd(true);
                        }}
                      >
                        Editar informações
                      </Dropdown.Item>
                    </CardListBodyItemOptions>
                  </Row>
                </CardListBody>
              ))}
              {data.length === 0 ? (
                <>
                  <CardListBody>
                    <Col
                      xs={12}
                      className="d-inline-flex align-items-center text-muted small justify-content-center"
                    >
                      Nenhum item encontrado.
                    </Col>
                  </CardListBody>
                </>
              ) : (
                <></>
              )}
            </CardList>
          </Col>
        </Row>
      </Container>
      <CustomModal
        show={showModalEditAdd}
        onHide={() => {
          setShowModalEditAdd(false);
          setUser(emptyUser);
        }}
        title={user._id ? 'Editar Usuário' : 'Adicionar Usuário'}
        submitLabel={user._id ? 'Salvar Alterações' : 'Adicionar Usuário'}
        onSubmit={submitData}
        errorMessage={errorMessage}
        type="form"
      >
        <UserForm user={user} handleInputChange={handleInputChange} />
      </CustomModal>
    </Sidebar>
  );
};

export default UsersPage;
