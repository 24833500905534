import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faQrcode,
  faBars,
  // faBell,
  faArrowRightFromBracket,
  faHouse,
  faUsers,
  faChartSimple,
  faCalendarDays,
} from '@fortawesome/free-solid-svg-icons';
// import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import {
  Navbar,
  Nav,
  Accordion,
  Card,
  Container,
  Row,
  Col,
  Dropdown,
  DropdownButton,
  Button,
} from 'react-bootstrap';
import BeeThumbsUp from '../../assets/img/bee_thumbs_up.webp';
import { CustomModal, CustomModalCompanies } from '../Modal';
import authService from '../../services/authService';
import configService from '../../services/configService';
import companyService from '../../services/companyService';
import { useAuth } from '../../context/authContext';
import Loading from '../Loading';
import { CompanyForm } from '../Forms';
import { getCompanyName } from '../../config/utils';
import Swal from 'sweetalert2';

interface Company {
  _id?: string;
  legalName?: string;
  fullName?: string;
  cnpj?: string;
  phone?: string;
  addressStreet?: string;
  addressNumber?: string;
  addressNeighborhood?: string;
  addressComplement?: string;
  addressCity?: string;
  addressState?: string;
  addressZipCode?: string;
}

interface CompanyContext {
  _id: string;
  fullName: string;
  legalName: string;
}

function Sidebar({ children, pageName, pageUrl, loading = false }: any) {
  const {
    user,
    selectedCompany,
    changeSelectedCompany,
    changeSelectedCompanyAdmin,
  } = useAuth();
  const [load, setLoad] = useState(loading);
  const [company, setCompany] = useState<Company>({});
  const [showModal, setShowModal] = useState(false);
  const [showModalEditAdd, setShowModalEditAdd] = useState(false);
  const [companies, setCompanies] = useState<Company[]>([]);

  const [sidebar, setSidebar] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const toggleSidebar = () => setSidebar(!sidebar);

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === 'addressZipCode') {
      const cleanedValue = value.replace(/[^0-9]/g, '').slice(0, 8);
      if (cleanedValue.length === 8) {
        setLoad(true);
        try {
          const response =
            await configService.getAddressByZipcode(cleanedValue);
          setCompany(prevCompany => ({
            ...prevCompany,
            addressStreet: response.logradouro,
            addressNeighborhood: response.bairro,
            addressCity: response.localidade,
            addressState: response.uf,
            addressZipCode: cleanedValue,
          }));
        } catch (error: any) {
          setErrorMessage(error.message);
        }
        setLoad(false);
      } else {
        setErrorMessage('');
        setCompany(prevCompany => ({
          ...prevCompany,
          addressStreet: '',
          addressNeighborhood: '',
          addressCity: '',
          addressState: '',
        }));
      }
    } else {
      setCompany(prevCompany => ({
        ...prevCompany,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async () => {
    setLoad(true);
    try {
      await companyService.createCompany(company).then(() => {
        setLoad(false);
        Swal.fire({
          icon: 'success',
          title: 'Empresa cadastrada com sucesso!',
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          window.location.reload();
        });
      });
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  };

  const getCompanies = async () => {
    setLoad(true);
    try {
      await companyService
        .findData()
        .then(result => {
          setLoad(false);
          setCompanies(result);
          setShowModal(true);
        })
        .catch(err => {
          setLoad(false);
          console.log(err);
        });
    } catch (error) {
      console.error('Erro ao buscar Empresas:', error);
      setLoad(false);
    }
  };

  const changeCompany = (companyId: string) => {
    setLoad(true);

    if (user?.role === 'admin') {
      const item = companies.find(company => company._id === companyId);
      changeSelectedCompanyAdmin(item as CompanyContext);
    } else {
      changeSelectedCompany(companyId);
    }

    setLoad(false);
    setShowModal(false);
  };

  return (
    <>
      <div className={sidebar ? 'd-flex' : `d-flex toggled`} id="wrapper">
        <div id="sidebar-wrapper" className="d-none d-lg-block">
          <div className="sidebar-heading d-flex align-items-center justify-content-center flex-column">
            <Link
              to="/home"
              className="d-flex align-content-center justify-content-center text-decoration-none"
            >
              {' '}
              <img
                src="https://fakeimg.pl/250x250/fff/d8401e/?text=beeventz"
                alt=""
                className="img-fluid mb-2 circle-img"
              />
            </Link>
          </div>
          {selectedCompany && (
            <div className="d-flex justify-content-center mb-3">
              <Button className="btn btn-qrcode">
                <FontAwesomeIcon icon={faQrcode} className="me-2" />
                LEITOR DE QR CODE
              </Button>
            </div>
          )}
          <Accordion className="mb-2 mb-md-5">
            {/* DASHBOARD */}
            <Card className="border-0">
              <Card.Header className="p-0 border-0">
                <NavItem icon={faHouse} title={'Home'} href="/home" />
                {/* ADMIN */}
                {user?.role === 'admin' && !selectedCompany && (
                  <>
                    <NavItem
                      icon={faChartSimple}
                      title={'Dashboard'}
                      href="/dashboard"
                    />
                    <NavItem icon={faUsers} title={'Usuários'} href="/users" />
                  </>
                )}

                {/* COMPANY */}
                {selectedCompany && (
                  <>
                    <NavItem
                      icon={faUsers}
                      title={'Usuários'}
                      href="/company/users"
                    />
                    <NavItem
                      icon={faCalendarDays}
                      title={'Eventos'}
                      href="/company/events"
                    />
                  </>
                )}

                {/* <NavItem icon={faCalendar} title={'Eventos'} href="/events" />
                <NavItem icon={faTicket} title={'Ingressos'} href="/tickets" />
                <NavItem
                  icon={faDollarSign}
                  title={'Financeiro'}
                  href="/finances"
                />
                <NavItem
                  icon={faChartPie}
                  title={'Relatórios'}
                  href="/reports"
                />
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <NavItem
                      icon={faHandshake}
                      title={'Parceiros'}
                      type="collapse"
                      onClick={() => handleCollapse('partners')}
                      collapsed={collapses['partners']}
                    />
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="d-flex flex-column color-gray">
                      <Link to={'/administrativo/agencias'}>item 1</Link>
                      <Link to={'/administrativo/contratos'}>item 2</Link>
                      <Link to={'/administrativo/gastos-extras'}>item 3</Link>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <NavItem icon={faUsers} title={'Usuários'} href="/users" />
                <NavItem icon={faUserTie} title={'Equipe'} href="/team" />
                <NavItem
                  icon={faBell}
                  title={'Notificações'}
                  href="/notifications"
                />
                <NavItem
                  icon={faCircleQuestion}
                  title={'Suporte'}
                  href="/support"
                /> */}
              </Card.Header>
            </Card>
          </Accordion>
          <div className="sidebar-logout-container">
            {user?.role === 'user' && user?.companies?.length === 0 && (
              <div className="d-flex justify-content-center d-block d-md-none">
                <Button
                  className="me-3 btn-company text-uppercase color-orange"
                  onClick={() => setShowModalEditAdd(true)}
                >
                  <span className="material-icons-outlined me-2">
                    celebration
                  </span>
                  Sou Promotor
                </Button>
              </div>
            )}
            {(user?.role === 'admin' ||
              (user?.companies && user?.companies?.length > 0)) && (
              <div className="d-flex flex-column justify-content-center color-white company-div">
                <small className="color-white ms-3">Empresa Selecionada:</small>
                <div
                  className="d-flex w-100 selected-company c-pointer py-2"
                  onClick={() => {
                    getCompanies();
                  }}
                >
                  {selectedCompany ? (
                    <strong className="ps-3">
                      {selectedCompany.legalName &&
                        getCompanyName(selectedCompany.legalName, 24)}
                    </strong>
                  ) : (
                    <strong className="ps-3">Nenhuma selecionada</strong>
                  )}
                </div>
              </div>
            )}
            <hr className="hr-sidebar" />
            <LogoutItem
              icon={'logout'}
              name={'Logout'}
              className={'c-pointer'}
              onClick={() => authService.logout()}
            />
          </div>
        </div>
        <div
          id="page-content-wrapper"
          className="d-flex justify-content-between flex-column"
        >
          <div className="d-flex flex-column w-100 h-100 overflow-auto justify-content-between">
            <div>
              <Navbar
                collapseOnSelect
                expand="lg"
                className="py-3 bg-primary justify-content-between color-white"
              >
                <div className="ms-3 d-flex justify-content-center align-items-center">
                  <FontAwesomeIcon
                    icon={faBars}
                    onClick={toggleSidebar}
                    className="c-pointer"
                    style={{ fontSize: '22px' }}
                  />
                  <h6 className="ms-3 mt-1 mb-0 lh-1">
                    <a
                      href={pageUrl}
                      className="text-decoration-none me-2 color-white fw-bold"
                    >
                      {pageName}
                    </a>
                  </h6>
                </div>
                <Nav className="me-3 nav-items align-items-center">
                  {user?.role === 'user' && user?.companies?.length === 0 && (
                    <Button
                      className="me-3 btn-company text-uppercase"
                      onClick={() => setShowModalEditAdd(true)}
                    >
                      <span className="material-icons-outlined me-2">
                        celebration
                      </span>
                      Sou Promotor
                    </Button>
                  )}
                  {selectedCompany && (
                    <FontAwesomeIcon icon={faQrcode} className="c-pointer" />
                  )}
                  {/* <FontAwesomeIcon
                    icon={faCircleQuestion}
                    className="c-pointer"
                  />
                  <FontAwesomeIcon icon={faBell} className="c-pointer" /> */}
                  <DropdownButton
                    align={{ lg: 'end' }}
                    title={
                      <>
                        <img
                          src={BeeThumbsUp}
                          alt="avatar-user"
                          className="rounded-circle"
                        />
                      </>
                    }
                    id="dropdown-menu-align-right"
                    className="p-0 d-none d-sm-flex"
                  >
                    <Dropdown.Item
                      eventKey="4"
                      onClick={() => (window.location.href = '/my-profile')}
                    >
                      Editar Perfil
                    </Dropdown.Item>
                    {(user?.companyRole === 'owner' ||
                      user?.companyRole === 'co-owner') && (
                      <Dropdown.Item
                        eventKey="4"
                        onClick={() => (window.location.href = '/my-company')}
                      >
                        Editar Empresa
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item
                      eventKey="4"
                      onClick={() => authService.logout()}
                    >
                      Sair
                    </Dropdown.Item>
                  </DropdownButton>
                </Nav>
              </Navbar>
              <div className="p-4 bg-white">{children}</div>
            </div>
          </div>
          <div className="w-100 py-3 text-center small">
            <Container>
              <Row>
                <Col xs={12} className="d-flex justify-content-center">
                  <p className="color-dark-gray m-0">
                    © {new Date().getFullYear()} - Beeventz. Todos os Direitos
                    Reservados.
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <CustomModal
        show={showModalEditAdd}
        onHide={() => setShowModalEditAdd(false)}
        title={'Criar Conta de Promotor'}
        submitLabel="Criar"
        size="xl"
        onSubmit={handleSubmit}
        type="form"
        errorMessage={errorMessage}
      >
        <CompanyForm company={company} handleInputChange={handleInputChange} />
      </CustomModal>
      <CustomModalCompanies show={showModal} onHide={() => setShowModal(false)}>
        <select
          className="form-control input-search"
          value={selectedCompany?._id || ''}
          onChange={e => changeCompany(e.target.value)}
        >
          <option value="">Conta Pessoal</option>
          {companies.map((option: any, index) => (
            <option key={index} value={option._id}>
              {option.fullName}
            </option>
          ))}
        </select>
      </CustomModalCompanies>
      <Loading show={load} />
    </>
  );
}

function NavItem({ href = '#', icon, title, type, collapsed, onClick }: any) {
  const location = useLocation();
  const isActive = location.pathname === href;

  return (
    <div className="list-group list-group-flush">
      <Link
        className={`list-group-item list-group-item-action ${isActive ? 'item-active' : ''}`}
        to={href}
        onClick={onClick}
      >
        <div className="d-flex justify-content-between align-items-center">
          <span className="d-flex align-items-center ms-3">
            <div className="icon-container">
              <FontAwesomeIcon icon={icon} className="font-awesome" />
            </div>
            <span className="name-list">{title}</span>
          </span>
          {type === 'collapse' ? (
            collapsed ? (
              <span className="material-icons me-1">keyboard_arrow_up</span>
            ) : (
              <span className="material-icons me-1">keyboard_arrow_down</span>
            )
          ) : (
            <span className="material-icons me-1">chevron_right</span>
          )}
        </div>
      </Link>
    </div>
  );
}

function LogoutItem(props: any) {
  const { onClick } = props;

  return (
    <div className={`list-group list-group-flush ${props.className}`}>
      <div className="list-group-item list-group-item-action" onClick={onClick}>
        <span className="d-flex align-items-center ms-3">
          <div className="icon-container">
            <FontAwesomeIcon
              icon={faArrowRightFromBracket}
              className="font-awesome"
            />
          </div>
          <span className="name-list">Sair</span>
        </span>
      </div>
    </div>
  );
}

export default Sidebar;
