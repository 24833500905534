import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import Sidebar from '../../../../../components/Sidebar';
import eventService from '../../../../../services/eventService';
import { formatDateToLocalInput } from '../../../../../config/utils';
import { ButtonPrimary } from '../../../../../components/Buttons';
import { CreateEventForm } from '../../../../../components/Forms';
import { Loading } from '../../../../../components';
import Swal from 'sweetalert2';

const CreateEventsPage: React.FC = () => {
  const [slug, setSlug] = useState('');
  const [isSlugAvailable, setIsSlugAvailable] = useState(false);
  const [slugLoading, setSlugLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [typeOfError, setTypeOfError] = useState('');
  const [item, setItem] = useState({
    title: '',
    description: '',
    type: '',
    start: formatDateToLocalInput(new Date()),
    end: '',
    venueName: '',
    venueAddress: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    capacity: 0,
  });
  const [debouncedTitle, setDebouncedTitle] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(false);
    setTypeOfError('');
    const { name, value } = event.target;

    if (name === 'title') {
      setItem(prevItem => ({
        ...prevItem,
        title: value,
      }));
      setDebouncedTitle(value);
    } else if (name === 'start') {
      if (isNaN(Date.parse(value))) {
        setError(true);
        setTypeOfError('start');
        setItem(prevItem => ({
          ...prevItem,
          start: formatDateToLocalInput(new Date()),
        }));
      } else {
        const selectedDate = formatDateToLocalInput(new Date(value));
        const currentDate = formatDateToLocalInput(new Date());

        if (selectedDate < currentDate) {
          setError(true);
          setTypeOfError('start');
          setItem(prevItem => ({
            ...prevItem,
            start: currentDate,
          }));
        } else {
          setItem(prevItem => ({
            ...prevItem,
            start: value,
          }));
        }
      }
    } else if (name === 'end') {
      const startDate = new Date(item.start);
      const endDate = new Date(value);

      if (isNaN(Date.parse(value)) || endDate <= startDate) {
        setError(true);
        setTypeOfError('end');
        const adjustedEnd = formatDateToLocalInput(
          new Date(startDate.getTime() + 10 * 60000)
        );
        setItem(prevItem => ({
          ...prevItem,
          end: adjustedEnd,
        }));
      } else {
        setItem(prevItem => ({
          ...prevItem,
          end: value,
        }));
      }
    } else {
      setItem(prevItem => ({
        ...prevItem,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    if (debouncedTitle) {
      const timerId = setTimeout(async () => {
        setSlugLoading(true);
        const generatedSlug = await eventService.isSlugAvailable({
          title: debouncedTitle,
        });
        setSlug(generatedSlug);
        setSlugLoading(false);
        setIsSlugAvailable(true);
      }, 500);

      return () => {
        clearTimeout(timerId);
      };
    }
  }, [debouncedTitle]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    if (
      !item.venueAddress ||
      !item.city ||
      !item.state ||
      !item.country ||
      !item.zipCode
    ) {
      Swal.fire({
        icon: 'error',
        title: '⚠️ Atenção ⚠️',
        text: 'Você deve selecionar um endereço válido a partir das sugestões, com CEP e número.',
      });
      setLoading(false);
      return;
    }

    if (item.type === '') {
      Swal.fire({
        icon: 'error',
        title: '⚠️ Atenção ⚠️',
        text: 'Selecione um tipo de evento.',
      });
      setLoading(false);
      return;
    }

    try {
      const response = await eventService.createEvent(item);

      if (response) {
        console.log(response);
        setLoading(false);
        Swal.fire({
          icon: 'success',
          title: '🎉 Evento criado com sucesso!',
          showConfirmButton: false,
          timer: 2000,
        }).then(() => {
          window.location.href = `/company/events/${response.data.slug}`;
        });
        return;
      } else {
        Swal.fire({
          icon: 'error',
          title: '⚠️ Atenção ⚠️',
          text: 'Erro ao criar evento.',
        });
        setLoading(false);
        return;
      }
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: '⚠️ Atenção ⚠️',
        text: error.response.data?.message || error.message,
      });
      setLoading(false);
    }
  };

  return (
    <>
      <Sidebar pageName="Criar Eventos" pageUrl="/company/events/create">
        <Container fluid>
          <Row className="mb-4">
            <Col xs={12}>
              <h1>Hora de Criar seu Evento! 💃</h1>
            </Col>
          </Row>
          <Form onSubmit={handleSubmit}>
            <CreateEventForm
              item={item}
              setItem={setItem}
              handleInputChange={handleInputChange}
              error={error}
              typeOfError={typeOfError}
              slugLoading={slugLoading}
              slug={slug}
              isSlugAvailable={isSlugAvailable}
            />
            <Row>
              <Col xs={12} className="d-flex justify-content-end">
                <ButtonPrimary
                  type="submit"
                  btnText={'Salvar'}
                  className="mt-2"
                />
              </Col>
            </Row>
          </Form>
        </Container>
      </Sidebar>
      <Loading show={loading} />
    </>
  );
};

export default CreateEventsPage;
