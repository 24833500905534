import React, { useState, useCallback, useEffect } from 'react';
import Sidebar from '../../../components/Sidebar';
import { Container, Row, Col } from 'react-bootstrap';
import { useAuth } from '../../../context/authContext';
import { getFirstName } from '../../../config/utils';
import Loading from '../../../components/Loading';
import { User, UserPayload, UserPassword } from '../../../types/User';
import userService from '../../../services/userService';
import { MyUserForm, MyPassowrdForm } from '../../../components/Forms';
import { ButtonPrimary } from '../../../components/Buttons';
import Swal from 'sweetalert2';

const MyProfilePage: React.FC = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState<User | null>();
  const [itemPassword, setItemPassword] = useState<UserPassword>({
    password: '',
    confirmPassword: '',
  });
  const [item, setItem] = useState<UserPayload>();

  const refreshItems = async () => {
    setLoading(false);
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await userService.getMyData();
      setUserData(response);
    } catch (error: any) {
      Swal.fire('Erro!', error.message, 'error');
    } finally {
      refreshItems();
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleInputChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    const { name, value } = e.target;

    if (type === 'password') {
      setItemPassword(
        prevItemPassword =>
          ({
            ...prevItemPassword,
            [name]: value,
          }) as UserPassword
      );
    } else {
      setItem(
        prevItem =>
          ({
            ...prevItem,
            [name]: value,
          }) as UserPayload
      );
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);

    try {
      await userService.updateMyData(item);
      Swal.fire('Sucesso!', 'Dados atualizados com sucesso!', 'success');
    } catch (error: any) {
      Swal.fire('Erro!', error.message, 'error');
    } finally {
      fetchData();
      setLoading(false);
    }
  };

  const handleSubmitPassword = async (event: any) => {
    event.preventDefault();
    setLoading(true);

    if (itemPassword.password && itemPassword.password.length < 8) {
      Swal.fire(
        'Erro!',
        'Sua senha deve ter pelo menos 8 caracteres.',
        'error'
      );
      setLoading(false);
      return;
    }

    if (itemPassword.password !== itemPassword.confirmPassword) {
      Swal.fire('Erro!', 'As senhas devem ser iguais.', 'error');
      setLoading(false);
      return;
    }

    try {
      await userService.updateMyPassword(itemPassword);
      Swal.fire('Sucesso!', 'Senha atualizada com sucesso!', 'success');
    } catch (error: any) {
      Swal.fire('Erro!', error.message, 'error');
    } finally {
      fetchData();
      setLoading(false);
    }
  };

  return (
    <>
      <Sidebar pageName="Minha empresa" pageUrl="/my-company">
        <Container fluid>
          <Row className="mb-4">
            <Col xs={12}>
              <h2>
                Essa é a sua página, {getFirstName(user?.fullName ?? '')}!
              </h2>
            </Col>
          </Row>
          <Row>
            <form onSubmit={handleSubmit}>
              <MyUserForm
                user={userData}
                handleInputChange={e => handleInputChange(e, 'user')}
              />
              <Row>
                <Col xs={12} className="d-flex justify-content-end">
                  <ButtonPrimary
                    type="submit"
                    btnText={'Salvar'}
                    className="mt-2"
                  />
                </Col>
              </Row>
            </form>
          </Row>
          <Row>
            <Col xs={12}>
              <h4>Alterar senha</h4>
            </Col>
            <form onSubmit={handleSubmitPassword}>
              <MyPassowrdForm
                user={itemPassword}
                handleInputChange={e => handleInputChange(e, 'password')}
              />
              <Row>
                <Col xs={12} className="d-flex justify-content-end">
                  <ButtonPrimary
                    type="submit"
                    btnText={'Salvar'}
                    className="mt-2"
                  />
                </Col>
              </Row>
            </form>
          </Row>
        </Container>
      </Sidebar>
      <Loading show={loading} />
    </>
  );
};

export default MyProfilePage;
