import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import AppRoutes from './routes';

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <script
          src={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_PLACES_KEY}&libraries=places&loading=async`}
          async
          defer
        ></script>
      </Helmet>
      <AppRoutes />
    </HelmetProvider>
  );
};

export default App;
