import React from 'react';
import Sidebar from '../../../../components/Sidebar';

const UsersCompaniesPage: React.FC = () => {
  return (
    <Sidebar pageName="Usuários" pageUrl="/company/users">
      <h1>Usuários Page</h1>
      <p>Welcome to the users page!</p>
    </Sidebar>
  );
};

export default UsersCompaniesPage;
