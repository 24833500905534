import moment from 'moment';

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

export const dateFormat = (date: Date) => {
  return moment.utc(date).format('DD/MM/YYYY');
};

export const dateHourFormat = (date: Date) => {
  if (!date) {
    return '';
  }
  return moment(date).format('DD/MM/YYYY HH:mm');
};

export const calculateAge = (date: Date) => {
  const currentDate = moment();
  const birthDate = moment.utc(date);

  return currentDate.diff(birthDate, 'years');
};

export const isUserMinor = (birthDate: Date) => {
  const today = new Date();
  const birth = new Date(birthDate);
  let age = today.getFullYear() - birth.getFullYear();
  const m = today.getMonth() - birth.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birth.getDate())) {
    age--;
  }
  return age < 18;
};

export const cpfFormat = (cpf: string) => {
  if (!cpf || cpf.length !== 11) {
    return 'CPF inválido';
  }
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
};

export const cnpjFormat = (cnpj: string) => {
  if (!cnpj || cnpj.length !== 14) {
    return 'CNPJ inválido';
  }
  return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
};

export const formatPrice = (price: number) => {
  if (typeof price !== 'number') return 'Não informado.';
  return `R$ ${price.toFixed(2).replace('.', ',')}`;
};

export const formatDateForInput = (isoDateString: string) => {
  if (!isoDateString || isoDateString === '') return '';
  return isoDateString.split('T')[0];
};

export const getTodayDate = function () {
  const now = new Date();
  const localOffset = now.getTimezoneOffset() * 60000;
  const localTime = new Date(now.getTime() - localOffset);
  return localTime.toISOString().split('T')[0];
};

export const formatPostalCode = (postalCode: string) => {
  let onlyNumbers = postalCode.replace(/\D/g, '');
  while (onlyNumbers.length < 8) {
    onlyNumbers = '0' + onlyNumbers;
  }

  return onlyNumbers;
};

export function formatBrazilianAddress(address: any) {
  if (!address) {
    return '';
  }

  const { street, number, neighborhood, city, uf, postalCode } = address;

  return `${street}, ${number} - ${neighborhood}, ${city}-${uf} - ${postalCode}`;
}

export function getFirstName(name: string) {
  return name.split(' ')[0];
}

export function formatPhone(phone: string) {
  if (phone) {
    if (phone.length === 11) {
      return phone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    } else {
      return phone.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    }
  }
  return phone;
}

export function normalizeDocumentNumber(document: string) {
  return document.replace(/\D/g, '');
}

export function getInitials(fullName: string) {
  const ignoreList = ['da', 'de', 'do', 'dos', 'das'];

  const relevantWords = fullName
    .split(' ')
    .filter(
      word => word.length > 0 && !ignoreList.includes(word.toLowerCase())
    );

  if (relevantWords.length > 1) {
    const firstInitial = relevantWords[0][0].toUpperCase();
    const lastInitial =
      relevantWords[relevantWords.length - 1][0].toUpperCase();
    return firstInitial + lastInitial;
  } else if (relevantWords.length === 1) {
    return relevantWords[0][0].toUpperCase();
  }

  return '';
}

export function getCompanyName(name: string, limit: number) {
  if (name.length <= limit) return name;

  const trimmedText = name.substr(0, limit);
  const lastSpaceIndex = trimmedText.lastIndexOf(' ');
  if (lastSpaceIndex === -1) return trimmedText + '...';

  return trimmedText.substr(0, lastSpaceIndex) + '...';
}

export const formatDateToLocalInput = (date: Date) => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .slice(0, 16);
};

export const getAddressComponent = (
  addressComponents: AddressComponent[],
  type: string
): string => {
  const component = addressComponents.find((cmp: AddressComponent) =>
    cmp.types.includes(type)
  );
  return component ? component.long_name : '';
};

export const limitText = (text: string, limit: number) => {
  if (text.length <= limit) return text;
  return text.substring(0, limit) + '...';
};
